import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { environment } from "../environments/environment.prod";
/* import new Module component ... */
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JWT_OPTIONS, JwtModule } from "@auth0/angular-jwt";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { Storage } from "@ionic/storage";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { ActionSheet } from "@awesome-cordova-plugins/action-sheet/ngx";
import { WheelSelector } from "@awesome-cordova-plugins/wheel-selector/ngx";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { OneSignal } from "@awesome-cordova-plugins/onesignal/ngx";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { LocalNotifications } from "@awesome-cordova-plugins/local-notifications/ngx";
import { myTransitionAnimation } from "./_helper/pageAnimation";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { HttpRequestInterceptor } from "./interceptors/loading.interceptor";
import { BackgroundMode } from "@awesome-cordova-plugins/background-mode/ngx";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { NgxsModule } from "@ngxs/store";
import { SessionState } from "./states/session.state";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { EmailComposer } from "@awesome-cordova-plugins/email-composer/ngx";
import { Globalization } from "@awesome-cordova-plugins/globalization/ngx";
import { MLKitTranslate } from "@awesome-cordova-plugins/mlkit-translate/ngx";
import { IonicStorageModule } from "@ionic/storage-angular";
import { Camera, CameraOptions } from "@awesome-cordova-plugins/Camera/ngx";
import { FilePath } from "@awesome-cordova-plugins/file-path/ngx/";

// import { NgxSpinnerModule } from "ngx-spinner";
import { File } from "@awesome-cordova-plugins/file/ngx";
import {
  FirebaseApp,
  initializeApp,
  provideFirebaseApp,
} from "@angular/fire/app";
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/analytics";
import {
  provideRemoteConfig,
  getRemoteConfig,
} from "@angular/fire/remote-config";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { VideoService } from "./services/video.service";
import { FileTransfer } from "@awesome-cordova-plugins/file-transfer/ngx";
// import { AndroidPermissionsOriginal } from "@awesome-cordova-plugins/android-permissions/ngx";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";

const config: SocketIoConfig = { url: `${environment.url}/chat`, options: {} };

export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get("access_token");
    },
    whitelistedDomains: [
      "34.87.94.22",
      "192.168.1.123:3000",
      "192.168.0.118:3000",
    ],
  };
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  imports: [
    BrowserModule,
    HttpClientModule,
    // NgxSpinnerModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IonicModule.forRoot({
      swipeBackEnabled: false,
      rippleEffect: false,
      // navAnimation: myTransitionAnimation,
      // alertEnter: customAlertEnter
    }),
    IonicStorageModule.forRoot(),
    SocketIoModule.forRoot(config),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage],
      },
    }),
    NgxsModule.forRoot([SessionState], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot(),
    AppRoutingModule,
    provideAnalytics(() => getAnalytics()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
  ],
  providers: [
    StatusBar,
    VideoService,
    File,
    Camera,
    FilePath,
    // AndroidPermissionsOriginal,
    FileTransfer,
    Globalization,
    SplashScreen,
    InAppBrowser,
    EmailComposer,
    ActionSheet,
    WheelSelector,
    OneSignal,
    LocalNotifications,
    Network,
    BackgroundMode,
    ScreenOrientation,
    MLKitTranslate,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    HTTP,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
