import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { enableProdMode } from "@angular/core";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);

defineCustomElements(window);
