import { Injectable } from "@angular/core";
import {
  AlertController,
  LoadingController,
  ModalController,
} from "@ionic/angular";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Storage } from "@ionic/storage";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import {
  tap,
  catchError,
  mapTo,
  mergeMap,
  finalize,
  delay,
  switchMap,
  map,
} from "rxjs/operators";
import { environment } from "../../environments/environment.prod";
import { HttpClient } from "@angular/common/http";
import OneSignal from "onesignal-cordova-plugin";
import { LoadingPresentation } from "../_helper";
import { Router } from "@angular/router";
const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  url = `${environment.url}/api`;
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  private userTypeSubject = new BehaviorSubject<string>(null);
  userType$ = this.userTypeSubject.asObservable();
  currentAccessToken = null;
  constructor(
    private http: HttpClient,
    private helper: JwtHelperService,
    private storage: Storage,
    private alertController: AlertController,
    private loadingCtrl: LoadingController,
    private router: Router,
    private modalCtrl: ModalController
  ) {
    this.checkToken();
  }
  /**
   * @function checkToken
   * @param
   * @returns Subject<User>
   */
  async checkToken() {
    let refreshToken = await this.getToken(REFRESH_TOKEN_KEY);
    let accessToken = await this.getToken(ACCESS_TOKEN_KEY);
    await this.checkRefreshToken(refreshToken, accessToken);
  }

  //check access token
  checkAccessToken(accessToken) {
    if (accessToken) {
      this.currentAccessToken = accessToken;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  //check refresh token
  async checkRefreshToken(refreshToken, accessToken) {
    if (refreshToken) {
      let isRefreshTokenExpired = this.helper.isTokenExpired(refreshToken);
      if (!isRefreshTokenExpired) {
        let refreshUser = this.helper.decodeToken(refreshToken);
        this.userTypeSubject.next(refreshUser.userType);
        this.checkAccessToken(accessToken);
      } else {
        await this.removeToken(REFRESH_TOKEN_KEY);
        await this.removeToken(ACCESS_TOKEN_KEY);
        this.isAuthenticated.next(false);
        this.router.navigateByUrl("/auth", { replaceUrl: true });
      }
    } else {
      this.isAuthenticated.next(false);
    }
  }

  /**
   * @function logout
   * @param refreshToken
   */
  async logout() {
    this.currentAccessToken = null;
    this.isAuthenticated.next(false);
    this.userTypeSubject.next(null);
    const deleteAccess = this.removeToken(ACCESS_TOKEN_KEY);
    const deleteRefresh = this.removeToken(REFRESH_TOKEN_KEY);
    await from(Promise.all([deleteAccess, deleteRefresh]));

    let modal = document.querySelector("ion-modal");
    if (modal) {
      this.modalCtrl.dismiss();
    }
    let loading = document.querySelector("ion-loading");
    if (loading) {
      this.loadingCtrl.dismiss();
    }
    this.router.navigateByUrl("/auth/login", { replaceUrl: true });
  }

  /**
   * sign in with email and password when phone number is compulsory
   * @param credentialInfo
   * @returns
   */
  signinWithEmailAndPass(credentialInfo?: {
    email: string;
    password: string;
  }): Observable<any> {
    return this.http.post(`${this.url}/signin/firstStep`, credentialInfo).pipe(
      catchError((e) => {
        this.show_alert(e.error.message);
        throw e.error.message;
      })
    );
  }

  /**
   * store required infos when phone number is compulsory
   * @param tokens
   */
  async signinSecondStep(tokens) {
    this.currentAccessToken = tokens["access_token"];
    let user = this.helper.decodeToken(this.currentAccessToken);
    OneSignal.sendTag("userID", user._id);
    this.userTypeSubject.next(user.userType);
    await this.storeToken(ACCESS_TOKEN_KEY, tokens["access_token"]);
    await this.storeToken(REFRESH_TOKEN_KEY, tokens["refresh_token"]);
    this.isAuthenticated.next(true);
    return user;
  }
  /**
   * send verify code to phone number
   */
  sendVerifyCodeToUser({ countryCode, phoneNumber }) {
    let loading = LoadingPresentation.show_loading(this.loadingCtrl);
    return this.http
      .post(`${this.url}/signin/secondStep`, { countryCode, phoneNumber })
      .pipe(
        mapTo(true),
        catchError((e) => {
          this.show_alert(e.error.message);
          throw e.error.message;
        }),
        finalize(() => {
          LoadingPresentation.remove_loading(loading);
        })
      );
  }
  /**
   * @function login
   * @param credentialInfo
   * @returns string
   */
  loginWith2FA(credentialInfo?: {
    email: string;
    password: string;
  }): Observable<any> {
    let loading = LoadingPresentation.show_loading(this.loadingCtrl);
    return this.http.post(`${this.url}/signin`, credentialInfo).pipe(
      switchMap(async (tokens) => {
        return await this.signinSecondStep(tokens);
      }),
      catchError((e) => {
        this.show_alert(e.error.message);
        throw e.error.message;
      }),
      finalize(() => {
        LoadingPresentation.remove_loading(loading);
      })
    );
  }

  /**
   * log in when phone number is optional
   * @param credentialInfo
   * @returns
   */

  login(credentialInfo?: { email: string; password: string }): Observable<any> {
    let loading = LoadingPresentation.show_loading(this.loadingCtrl);
    return this.http.post(`${this.url}/signin`, credentialInfo).pipe(
      switchMap(async (tokens) => {
        this.checkAccessToken(tokens["access_token"]);
        //get user
        let user = this.helper.decodeToken(this.currentAccessToken);
        await this.storeToken(ACCESS_TOKEN_KEY, tokens["access_token"]);
        await this.storeToken(REFRESH_TOKEN_KEY, tokens["refresh_token"]);
        //send user id to oneSingle
        OneSignal.setExternalUserId(user._id);

        OneSignal.sendTags({
          userID: user._id,
          company: user.company,
        });
        this.userTypeSubject.next(user.userType);
        //store tokens
        return user;
      }),
      catchError((e) => {
        alert(JSON.stringify(e));
        if (!e.error) {
          this.show_alert("Please try later");
        } else {
          this.show_alert(e.error);
        }

        throw e.error;
      }),
      finalize(() => {
        LoadingPresentation.remove_loading(loading);
      })
    );
  }

  sendOtp(credentialInfo?: {
    email: string;
    password: string;
  }): Observable<any> {
    let loading = LoadingPresentation.show_loading(this.loadingCtrl);
    return this.http.post(`${this.url}/sendotp`, credentialInfo).pipe(
      switchMap(async (data) => {
        return data["token"];
      }),
      catchError((e) => {
        if (!e.error.message) {
          this.show_alert("Please try later");
        } else {
          this.show_alert(e.error.message);
        }

        throw e.error.message;
      }),
      finalize(() => {
        LoadingPresentation.remove_loading(loading);
      })
    );
  }

  verifyOtp(credentialInfo?: {
    email: string;
    password: string;
    otp: string;
    token: string;
  }): Observable<any> {
    let loading = LoadingPresentation.show_loading(this.loadingCtrl);
    return this.http.post(`${this.url}/verifyotp`, credentialInfo).pipe(
      switchMap(async (tokens) => {
        this.checkAccessToken(tokens["access_token"]);
        let user = this.helper.decodeToken(this.currentAccessToken);

        try {
          //get user

          OneSignal.setExternalUserId(user._id);

          OneSignal.sendTags({
            userID: user._id,
            company: user.company,
          });
          OneSignal.setEmail(credentialInfo.email);

          this.userTypeSubject.next(user.userType);
          //store tokens
        } catch (error) {}
        await this.storeToken(ACCESS_TOKEN_KEY, tokens["access_token"]);
        await this.storeToken(REFRESH_TOKEN_KEY, tokens["refresh_token"]);
        return user;
      }),
      catchError((e) => {
        if (!e.error.message) {
          this.show_alert("Please try later");
        } else {
          this.show_alert(e.error.message);
        }

        throw e.error.message;
      }),
      finalize(() => {
        LoadingPresentation.remove_loading(loading);
      })
    );
  }

  //store token
  private storeToken(key, value) {
    return this.storage.set(key, value);
  }

  //store access token

  storeAccessToken(accessToken) {
    this.currentAccessToken = accessToken;
    return from(this.storeToken(ACCESS_TOKEN_KEY, accessToken));
  }

  // reissue new access token
  getNewAccessToken() {
    const refreshToken = from(this.getToken(REFRESH_TOKEN_KEY));
    return refreshToken.pipe(
      switchMap((token) => {
        if (token) {
          return this.http.post(`${this.url}/refresh`, { refreshToken: token });
        } else {
          // No stored refresh token
          return of(null);
        }
      })
    );
  }

  //get token
  private async getToken(key) {
    return this.storage.get(key);
  }

  //remove token
  private async removeToken(key) {
    return this.storage.remove(key);
  }

  //check current role
  hasRoles(roles: String[]) {
    if (
      !this.userTypeSubject.value ||
      !roles.includes(this.userTypeSubject.value)
    ) {
      return false;
    }
    return true;
  }
  /**
   * change password
   * @param passwordInfo
   * @returns
   */
  changePassword(passwordInfo?: {
    currentPassword: string;
    newPassword: string;
  }) {
    return this.http.post(`${this.url}/changepassword`, passwordInfo).pipe(
      catchError((err) => {
        this.show_alert(err.error.message);
        throw err.error.message;
      })
    );
  }

  /**
   * @function register with no 2fa
   * @param email, password ,firstName, lastName, phoneNumber
   * @returns Observable<boolbean>
   */
  register(credentialInfo, type) {
    return this.http.post(`${this.url}/coachee/signup`, credentialInfo).pipe(
      mergeMap(() => {
        if (type == "form") {
          return Promise.resolve(true);
        } else {
          return this.login(credentialInfo);
        }
      }),
      mapTo(true),
      catchError((e) => {
        let error = e?.error?.message;
        this.show_alert(error);
        throw error;
      })
    );
  }

  /**
   * @function register with no 2fa
   * @param email, password ,firstName, lastName, phoneNumber
   * @returns Observable<boolbean>
   */
  sendSignUpOtp(credentialInfo, type): Observable<any> {
    let loading = LoadingPresentation.show_loading(this.loadingCtrl);

    return this.http
      .post(`${this.url}/coachee/sendSignUpOtp`, credentialInfo)
      .pipe(
        switchMap(async (data) => {
          if (type == "form") {
            return null;
          } else {
            return data["token"];
          }
        }),
        catchError((e) => {
          if (!e.error.message) {
            this.show_alert("Please try later");
          } else {
            this.show_alert(e.error.message);
          }

          throw e.error.message;
        }),
        finalize(() => {
          LoadingPresentation.remove_loading(loading);
        })
      );
  }

  verifySignUpOtp(credentialInfo, type): Observable<any> {
    return this.http
      .post(`${this.url}/coachee/verifySignUpOtp`, credentialInfo)
      .pipe(
        mergeMap(() => {
          if (type == "form") {
            return Promise.resolve(true);
          } else {
            return this.login(credentialInfo);
          }
        }),
        mapTo(true),
        catchError((e) => {
          let error = e?.error?.message;
          this.show_alert(error);
          throw error;
        })
      );
  }
  /**
   * registered with no phone number
   * @param credentialInfo
   * @returns
   */
  registerWithNoPhoneNumber(credentialInfo) {
    return this.http.post(`${this.url}/coachee/signup`, credentialInfo).pipe(
      mergeMap(() => {
        return this.signinWithEmailAndPass(credentialInfo);
      }),
      catchError((e) => {
        let error = e.error.message;
        this.show_alert(error);
        throw error;
      })
    );
  }
  /**
   * register with 2fa
   * @param credentialInfo
   * @returns
   */
  registerWithPhoneNumberFirstStep(credentialInfo?: {
    email: string;
    countryCode: string;
    phoneNumber: string;
    companyCode: string;
  }) {
    return this.http
      .post(`${this.url}/coachee/signup/firstStep`, credentialInfo)
      .pipe(
        catchError((e) => {
          let error = e.error.message;
          this.show_alert(error);
          throw error;
        })
      );
  }

  /**
   * register with 2fa
   * @param credentialInfo
   * @returns
   */
  registerWithPhoneNumberSecondStep(credentialInfo) {
    return this.http.post(`${this.url}/coachee/signup`, credentialInfo).pipe(
      mapTo(true),
      catchError((e) => {
        let error = e.error.message;
        this.show_alert(error);
        throw error;
      })
    );
  }

  /**
   * verify code which user input
   * @param param0
   * @returns
   */
  verifyCode({ countryCode, phoneNumber, verifyCode }) {
    let loading = LoadingPresentation.show_loading(this.loadingCtrl);
    return this.http
      .post(`${this.url}/verify`, {
        countryCode,
        phoneNumber,
        verifyCode,
      })
      .pipe(
        mapTo(true),
        catchError((err) => {
          this.show_alert(err.error.message);
          throw err.error;
        }),
        finalize(() => {
          LoadingPresentation.remove_loading(loading);
        })
      );
  }
  // check company code
  check_company_code = (companyCode) => {
    return this.http
      .get(`${this.url}/companyCodes/?companyCode=${companyCode}`)
      .pipe(
        catchError((e) => {
          this.show_alert(e.error.message);
          throw e.error.message;
        })
      );
  };

  /**
   * @function update profile
   * @param profileInfo
   */
  updateProfile(profileInfo?) {
    let loading = LoadingPresentation.show_loading(this.loadingCtrl);
    return this.http.post(`${this.url}/profile`, profileInfo).pipe(
      tap(() => {
        LoadingPresentation.remove_loading(loading);
      }),
      mapTo(true),
      delay(500),
      catchError((err) => {
        LoadingPresentation.remove_loading(loading);
        this.show_alert(err.error);
        throw err.error;
      })
    );
  }

  /**
   * @function forget password
   * @param email
   */
  forgot_password(email) {
    let loading = LoadingPresentation.show_loading(this.loadingCtrl);
    return this.http.post(`${this.url}/forgotpassword`, email).pipe(
      tap(() => {
        LoadingPresentation.remove_loading(loading);
      }),
      mapTo(true),
      catchError((e) => {
        LoadingPresentation.remove_loading(loading);
        let error = e.error.message;
        if (!e.error.message) {
          this.show_alert("internet error");
          throw error;
        }
        this.show_alert(e.error.message);
        throw error;
      })
    );
  }

  /**
   * get user profile
   * @returns
   */
  get_user_profile() {
    return this.http.get(`${this.url}/profile`).pipe(
      catchError((e) => {
        let message = e.error || e.error.message;
        if (message == "Unauthorized") {
          message = "Access time expired";
        }
        this.show_alert(message);
        throw message;
      })
    );
  }

  async show_alert(msg) {
    let alert = await this.alertController.create({
      message: msg,
      buttons: ["OK"],
    });
    await alert.present();
  }
}
