import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route, Router } from '@angular/router';

import { AuthService } from './auth.service';

import { User } from '../model/user';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  currentUserType

  constructor(private authService: AuthService,private router:Router) {
    this.authService.userType$.subscribe(userType => {
      this.currentUserType = userType
    })
  }

  check(route, _state?: RouterStateSnapshot) {
      if (this.currentUserType) {

        if (route.data.allowUserType.includes(this.currentUserType))
          return true;
      }
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this.check(route, state);
  };

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.check(route, state);
  };

  canLoad(route: Route) {
    // return this.check(route);
    return this.authService.isAuthenticated.pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map(isAuthenticated => {
        if (isAuthenticated) {          
          return true;
        } else {          
          return false;
        }
      })
    );
  }
}
