import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AutoLoginGuard  {
  userType;
  constructor(private authService: AuthService, private router: Router) {
    this.authService.userType$.subscribe((userType) => {
      this.userType = userType;
    });
  }
  canLoad(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter((val) => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map((isAuthenticated) => {
        if (isAuthenticated) {
          switch (true) {
            case this.userType === "Coachee":
              // try {
              //   this.router.navigateByUrl("/startup/start", {
              //     replaceUrl: true,
              //   });
              // } catch (error) {
              //
              // }

              this.router.navigateByUrl("/coachee/home", { replaceUrl: true });
              break;
            case this.userType === "CommonCoach":
              this.router.navigateByUrl("/coach", { replaceUrl: true });
              break;
            case this.userType === "PartnerCoach":
              this.router.navigateByUrl("/coach", { replaceUrl: true });
              break;
            default:
              break;
          }
          // Directly open inside area
        } else {
          // Simply allow access to the login
          return true;
        }
      })
    );
  }
}
