export interface ScheduledSession {
  _id?: string;
  type?: string;
  credits?: number;
  creditsTakenFrom?: string;
  topics?: string[] | any[];
  preferences?: SessionPreferences;
  _coach?: string | any;
  _coachee?: string | any;
  startDate?: Date;
  endDate?: Date;
  company?: string | any;
  comments?: any[];
  eventUri?: string;
  inviteeUri?: string;
  cancelUri?: string;
  rescheduleUri?: string;
  completed?: boolean;
  isChatSession?: boolean;
  _therapySummary?: string | any;
}

export class AddSession {
  static readonly type = "[Session] Add Session";
  constructor(public session: ScheduledSession) {}
}

export interface SessionPreferences {
  participationType?: string;
  aditionalMembers?: string[];
  gender?: string;
  language?: string;
}
