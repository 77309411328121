import { Animation, createAnimation } from '@ionic/core';

export function customModalEnterAnimationForPlayer(baseEl: HTMLElement): Animation {

    const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', 0.01, 0.4);
    const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .fromTo('transform', 'translateY(100%)', 'translateY(0px)')
        .fromTo('opacity', 0.4, 1)
    const baseAnimation = createAnimation()
        .addElement(baseEl)
        .delay(500)
        .easing('cubic-bezier(0.36,0.66,0.04,1)')
        .duration(400)
        .beforeAddClass('show-modal')
        .addAnimation(backdropAnimation)
        .addAnimation(wrapperAnimation);
    return baseAnimation
}