import { LoadingController } from "@ionic/angular";
import { Camera, CameraOptions } from "@awesome-cordova-plugins/Camera/ngx";
import { File, IFile } from "@awesome-cordova-plugins/file/ngx";
import { environment } from "src/environments/environment.prod";
import { FilePath } from "@awesome-cordova-plugins/file-path/ngx";
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
} from "@awesome-cordova-plugins/file-transfer/ngx";
import { PhotoService } from "./photo.service";
import { Capacitor } from "@capacitor/core";
import Swal from "sweetalert2";
import { Injectable } from "@angular/core";

const MAX_FILE_SIZE = 50 * 1024 * 1024;
const ALLOWED_MIME_TYPE = "video/mp4";
@Injectable({
  providedIn: "root",
})
export class VideoService {
  public uploadedVideo: IFile;
  public selectedVideo: string;
  public isUploading: boolean = false;
  public uploadPercent: number = 0;
  loader;
  videoFileUpload: FileTransferObject;
  videoBuffer: ArrayBuffer;
  videoUrls;

  constructor(
    private camera: Camera,
    private loadingCtrl: LoadingController,
    private file: File,
    private filePath: FilePath,
    private transfer: FileTransfer
  ) {}

  public clearVideo() {
    this.uploadedVideo = undefined;
    this.selectedVideo = undefined;
    this.isUploading = false;
  }

  showLoader() {
    this.loader = this.loadingCtrl.create({
      message: "Please wait...",
      spinner: "crescent",
    });
    this.loader.present();
  }
  dismissLoader() {
    this.loader.dismiss();
  }

  async selectVideo() {
    try {
      const options: CameraOptions = {
        quality: 50,
        mediaType: this.camera.MediaType.VIDEO,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        destinationType: this.camera.DestinationType.NATIVE_URI,
      };
      var videoUrl = await this.camera.getPicture(options);

      if (videoUrl) {
        // this.showLoader();
        this.uploadedVideo = null;

        var filename = videoUrl.substr(videoUrl.lastIndexOf("/") + 1);
        var dirpath = videoUrl.substr(0, videoUrl.lastIndexOf("/") + 1);

        dirpath = dirpath.includes("file://") ? dirpath : "file://" + dirpath;

        try {
          var dirUrl = await this.file.resolveDirectoryUrl(dirpath);

          var retrievedFile = await this.file.getFile(dirUrl, filename, {});
        } catch (err) {
          // this.dismissLoader();
          if (err?.code == 5) {
            Swal.fire({
              title: "Oops",
              text: "We couldn't retrieve the file you selected, please try selecting it from photos by clicking on the menu icon on top and go to the file location",
              icon: "warning",
              heightAuto: false,
            });
          }

          return;
        }

        retrievedFile.file(async (data) => {
          // this.dismissLoader();

          if (data.size > MAX_FILE_SIZE)
            return alert("Error" + " You cannot upload more than 50mb.");
          // if (data.type !== ALLOWED_MIME_TYPE)
          //   return alert("Error" + "Incorrect file type.");

          this.uploadedVideo = data;
          this.selectedVideo = retrievedFile.nativeURL;
        });
      }
    } catch (err) {
      console.log("SELECT VIDEO ERROR", err);
      console.log(err);
    }
  }

  async uploadVideo() {
    try {
      var url = environment.url + "/api/challengeRecords/uploadVideo";

      var filename = this.selectedVideo.substr(
        this.selectedVideo.lastIndexOf("/") + 1
      );

      var options: FileUploadOptions = {
        fileName: filename,
        fileKey: "video",
        // mimeType: "video/mp4",
      };

      this.videoFileUpload = this.transfer.create();

      this.isUploading = true;

      var data = await this.videoFileUpload.upload(
        this.selectedVideo,
        url,
        options
      );
      this.isUploading = false;
      this.uploadPercent = 0;
      this.videoUrls = JSON.parse(data.response)["videoUrls"];

      return this.videoUrls;
    } catch (err) {
      this.isUploading = false;
      this.uploadPercent = 0;

      // alert(JSON.stringify(err));
      return null;
    }
  }

  cancelUpload() {
    this.videoFileUpload.abort();
    this.uploadPercent = 0;
  }
}
