import { Animation, createAnimation } from '@ionic/core';

export function customModalLeaveAnimation(baseEl: HTMLElement): Animation {

    const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', 0.01, 0.4);
    const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .fromTo('transform', 'scale(1,1)', 'translateX(0) scale(1,1)')
        .fromTo('opacity', 1, 0)
    const baseAnimation = createAnimation()
        .addElement(baseEl)
        .easing('cubic-bezier(0.36,0.66,0.04,1)')
        .duration(100)
        .beforeAddClass('show-modal')
        .addAnimation(backdropAnimation)
        .addAnimation(wrapperAnimation);
    return baseAnimation
}