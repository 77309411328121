import { Injectable } from "@angular/core";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { Platform } from "@ionic/angular";
import { Observable } from "rxjs";
import { mapTo } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NetworkService {
  private online$: Observable<boolean> = undefined;

  constructor(public network: Network, public platform: Platform) {
    this.online$ = new Observable((observer) => {
      observer.next(true);
    }).pipe(mapTo(true));
    this.online$ = this.network.onDisconnect().pipe(mapTo(false));
  }

  public getNetworkStatus(): Observable<boolean> {
    return this.online$;
  }
}
