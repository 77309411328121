export const environment = {
  firebase: {
    projectId: "uphealthgcp",
    appId: "1:503919098034:web:ec99167526bdee6ee671ab",
    storageBucket: "uphealthgcp.appspot.com",
    locationId: "asia-southeast2",
    apiKey: "AIzaSyD_lIRNh0V8I5r7ZQZe5ps03p8CuCPyDaI",
    authDomain: "uphealthgcp.firebaseapp.com",
    messagingSenderId: "503919098034",
    measurementId: "G-3N7VLH4799",
  },
  production: false,
  // url: "http://192.168.0.118:3000",
  url: "http://172.16.11.21:8080",

  // url: "https://production-dot-uphealthgcp.et.r.appspot.com",
  // url: "https://staging-dot-uphealthgcp.et.r.appspot.com",
  articleUrl: "",
  formUrl: "https://flourishsite.web.app/form",
  ONESIGNAL_ID: "83cea0f8-1467-4ecd-a670-ccd8d94c56c8",
  ANDROID_ID: "503919098034",
  assessments: {
    who5: "617f7520ef69c136e1fa1e88",
    paq: "62693d1ab092fb6c69c27716",
  },
};
