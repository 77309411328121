export const environment = {
  firebase: {
    projectId: "uphealthgcp",
    appId: "1:503919098034:web:ec99167526bdee6ee671ab",
    storageBucket: "uphealthgcp.appspot.com",
    locationId: "asia-southeast2",
    apiKey: "AIzaSyD_lIRNh0V8I5r7ZQZe5ps03p8CuCPyDaI",
    authDomain: "uphealthgcp.firebaseapp.com",
    messagingSenderId: "503919098034",
    measurementId: "G-3N7VLH4799",
  },
  versionCode: 32,
  production: true,
  // assessments: {
  //   who5: "629a0135ba370ee28d5c67fd",
  //   paq: "62693d1ab092fb6c69c27716",
  // },
  assessments: {
    who5: "62344e876658f07af1ec19b1",
    paq: "62b0aeec4330588baeea3f1b",
  }, //production assessments

  // url: "http://172.16.11.21:8080",
  // url: "http://localhost:8080",
  // url: "http://192.168.96.24:8080", //phone
  // url: "http://192.168.101.109:8080",

  articleUrl: "",
  formUrl: "https://survey.startflourish.com/form",
  // formUrl: "http://localhost:4200/form",

  fitbitClientSecret: "41c2532eb1603226f5c47256a18f5d45",
  fitbitClient: "239662",
  url: "https://production-dot-uphealthgcp.et.r.appspot.com",

  // url: "https://staging-dot-uphealthgcp.et.r.appspot.com",
  ONESIGNAL_ID: "83cea0f8-1467-4ecd-a670-ccd8d94c56c8",
  ANDROID_ID: "503919098034",
};
