import { Action, StateContext, Selector, State } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { AddSession, ScheduledSession } from "../model/ScheduledSession";

export interface SessionStateModel {
  session: ScheduledSession;
}

@State<ScheduledSession>({
  name: "session",
})
@Injectable()
export class SessionState {
  @Selector()
  static getSession(state: ScheduledSession) {
    return state;
  }

  @Action(AddSession)
  addSession(
    { setState }: StateContext<ScheduledSession>,
    { session }: AddSession
  ) {
    setState(session);
  }
}
