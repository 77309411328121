
import { LoadingController } from '@ionic/angular';
import { from } from 'rxjs';

function show_loading(loadingCtrl: LoadingController) {
   let loading = loadingCtrl.create({
        message: 'Please wait...',
        spinner: 'crescent'
    })
   loading.then(loading => {
        loading.present()
    })
    return loading
}

function remove_loading(loading:Promise<any>){
    from(loading).subscribe(res=>{
        res.dismiss()
    })
}

export const LoadingPresentation={
    show_loading,
    remove_loading
}