import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuardService } from "./services/auth-guard.service";
import { UserType } from "./model/userType";
import { AutoLoginGuard } from "./services/auto-login.guard";
const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./pages/auth/auth.module").then((m) => m.AuthModule),
    canLoad: [AutoLoginGuard],
  },
  // {
  //   path: "onbording",
  //   loadChildren: () =>
  //     import("./pages/coachee-tabs/onboarding/onboarding.module").then(
  //       (m) => m.OnboardingPageModule
  //     ),
  //   canLoad: [AutoLoginGuard],
  // },
  {
    path: "startup",
    loadChildren: () =>
      import("./pages/share/startup/startup.module").then(
        (m) => m.StartupPageModule
      ),
    canLoad: [AutoLoginGuard],
  },
  {
    path: "coachee",
    loadChildren: () =>
      import("./pages/coachee-tabs/coachee-tabs.module").then(
        (m) => m.CoacheeTabsPageModule
      ),
    data: { allowUserType: [UserType.coachee] },
    canActivateChild: [AuthGuardService],
    canLoad: [AuthGuardService],
  },
  {
    path: "coach",
    loadChildren: () =>
      import("./pages/coach-tabs/coach-tabs.module").then(
        (m) => m.CoachTabsPageModule
      ),
    data: { allowUserType: [UserType.coach, UserType.partnerCoach] },
    canActivateChild: [AuthGuardService],
    canLoad: [AuthGuardService],
  },
  {
    path: "",
    redirectTo: "auth",
    pathMatch: "full",
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
