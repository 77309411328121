import { Platform, AlertController } from "@ionic/angular";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { environment } from "../environments/environment.prod";
import { NetworkService } from "./services/network.service";
import { debounceTime } from "rxjs/operators";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { BackgroundMode } from "@awesome-cordova-plugins/background-mode/ngx";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { MLKitTranslate } from "@awesome-cordova-plugins/mlkit-translate/ngx";
import { Storage } from "@ionic/storage-angular";
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions";
import OneSignal from "onesignal-cordova-plugin";
import { register } from "swiper/element/bundle";
import { Component, NgZone } from "@angular/core";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";

register();
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  applationVersion: any;
  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private backgroundMode: BackgroundMode,
    private alertController: AlertController,
    private networkService: NetworkService,
    private screenOrientation: ScreenOrientation,
    private splashScreen: SplashScreen,
    private router: Router,
    private zone: NgZone,
    private mlKitTranslate: MLKitTranslate,
    private storage: Storage,
    private http: HTTP
  ) {
    this.initializeApp();
  }

  initializeApp() {
    try {
      this.mlKitTranslate.downloadModel("zh");
    } catch (error) {
      alert(error);
    }

    this.platform.ready().then(() => {
      // set to PORTRAIT
      this.statusBar.styleDefault();
      this.setupDeepLink();

      if (this.platform.is("cordova")) {
        this.backgroundMode.enable();
        this.splashScreen.hide();
        this.screenOrientation.lock(
          this.screenOrientation.ORIENTATIONS.PORTRAIT
        );
        //detect network
        this.networkService
          .getNetworkStatus()
          .pipe(debounceTime(300))
          .subscribe((connected: boolean) => {
            this.show_alert("network was disconnected :-(");
          });

        //initialize notification
        this.setupPush();
      }
    });
  }
  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    this.platform.ready().then(() => {
      this.http
        .setServerTrustMode("pinned")
        .then(() => {
          console.log("Congratulations, you have setted up SSL Pinning.");
        })
        .catch((error) => {
          console.log(error);
          console.log("SSL Pinning Failed.");
        });
    });
    await this.storage.create();
  }

  setupDeepLink() {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".com").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }
  async setupPush() {
    // I recommend to put these into your environment.ts

    try {
      OneSignal.setAppId(environment.ONESIGNAL_ID);

      // Notifcation was received in general
      OneSignal.setNotificationOpenedHandler(function (jsonData) {
        const slug = jsonData.notification.launchURL.split(".com").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
      OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
        if (!accepted) {
          this.show_alert(
            "Please allow notifications for Start Flourish in settings"
          );
        }
      });
    } catch (error) {
      alert(JSON.stringify(error));
    }
  }
  async show_alert(msg) {
    let alert = await this.alertController.create({
      message: msg,
      buttons: ["OK"],
    });
    await alert.present();
  }
}
